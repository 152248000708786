// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DynamicModuleGenerator from '../../components/dynamic-module-generator';

const mapStateToProps = ({ viewport }) => ({
    viewport,
});

const Group = ({ innerBlocks, viewport }) => {
    return <DynamicModuleGenerator content={innerBlocks} viewport={viewport} />;
};

Group.propTypes = {
    innerBlocks: PropTypes.array,
    viewport: PropTypes.object,
};

Group.parseProps = atts => atts;

export default connect(mapStateToProps)(Group);
